@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: 'Roboto', sans-serif !important;
}

body {
  background-color: #f2f2f2 !important;
}

.animatedPath {
  stroke-dasharray: 1000; /* A large enough number */
  stroke-dashoffset: 1000;
}